import React, { Component } from 'react';
import * as Constants from '../../../common/Global/constants';
import {PageSaveButton,FilterCheckBox,TaskTagButtonsLayout, AddNewButtonLayout,AlertInfo} from '../../../common/Global/globalStyles.style';
import Sidebar from '../../../common/SidebarCollapse';
import Topbar from '../../../common/Topbar';
import MasterMenuTabs from "../Common/topmenu";
import RegularDropdown from '../../../common/DropdownMenus/RegularDropdown';
import {getLocalStorageVariables,getThemeSettingsVariables, GetTemplateTabMenu,getStatusSpan,
    isEmptyVariable, isEmptyArray, isJsonString} from '../../../common/Global/commonFunctions';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { CheckBoxSelection, Inject, MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-react-dropdowns/styles/material.css";
import * as TemplateConstants from "../../../common/Global/templateConstants";
import AddFieldDialog from '../Common/addFieldDialog';
import { DatePicker } from 'antd';
import AddTemplateNamedialog from '../Common/addTemplateNamedialog'
import AlertDialog from '../../../common/AlertDialog';
import LeftSideSelectDialog from '../../Tasks/Common/selectDropdownValues';
import TableDropDown from "../../../common/DropdownMenus/TableDropdown";
import DifferenceDialog from '../Common/differenceDialog'
import SelectAssignments from '../Common/selectAssignments'
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";
import { Spinner } from 'react-bootstrap';

const themeSettings  = getThemeSettingsVariables();
const userDetails  = getLocalStorageVariables();
const addfieldTypePlaceholder = "Add Field";
const templateNamePlaceholder = "Template Name Here";
const templateAlertDialogMessage = "Any change in template may have impact on download report.";


const editDropdownObj = {icon: "edit",label: "Edit"};
const enableDropdownObj = {icon: "toggle_on",label: "Enable Field"};
const disableDropdownObj = {icon: "toggle_off",label: "Disable Field"};
const delDropdownObj = {icon: "delete",label: "Delete"};
const discardChangesObj = {icon: "backspace",label: "Discard Changes",}
const differenceDropdownObj = {icon: "compare_arrows",label: "Show Differences"};
let isDetails = false;
let isTemplateEdit = false;
let isTemplateAlertDialog =false;

class AddEditTaskTemplates extends Component{
    
    constructor(props) {
        super(props);

        let isEdit = false;
        let templateId = "";
        let departmentId = "";
        if(this.props.location.pathname.includes(Constants.URL_EDIT_OBSERVATION_TEMPLATE)){
            isEdit = true;
            isDetails = false;
            templateId = this.props.match.params.id;
            isTemplateEdit=true;
            isTemplateAlertDialog=true;
        }else if(this.props.location.pathname.includes(Constants.URL_OBSERVATION_TEMPLATE_DETAILS)){
            isEdit = true;
            isDetails = true;
            templateId = this.props.match.params.id;
        }else{
            isDetails = false;
            departmentId = this.props.match.params.id;
        }

        this.state = {
            menus:{},
            componentDidMountFlag:false,
            templateId:templateId,
            departmentId:departmentId,
            datalist:[],
            templateFieldsMap:{},
            datalistLeft:[],
            templateFieldsLeftMap:{},
            fixedTemplateFields:{},
            fixedTemplateFields_prev:"{}",
            fieldUsedInObs:false,
            editTemplateItem:{},
            editTemplateItem_prev:{},
            // formErrors:{},
            templateFields_prev:"[]",
            templateFieldsLeft_prev:"[]",
            fieldUseCountMap:{},
            assignmentCount:0,
            updateAssignmentIds:[],
            isEdit:isEdit,

            fieldNameArray:[],
            showCreateDialog:false,
            deleteReactivateId:"",
            addfieldTypePlaceholder:addfieldTypePlaceholder,
            businessRisk:[],
            templateName:templateNamePlaceholder,
            
            showAddTemplateNameDialog:false,

            showAlertDialog:false,
            operationType:"",
            deleteReactivateId:"",
            deleteReactivateMasterType:"",
            alertDialogMessage:"",
            
            showAlertDialogInfo:false,
            alertDialogMessageInfo:"",

            showSecondAlertDialog:false,
            secondAlertDialogMessage:"",
            secondAlertProceedBtnLabel:"",

            showSelectDropdownDialog:false,
            showDifferenceDialog:false,

            showTemplateAlertDialog:isTemplateAlertDialog,
            showTemplateInfoDialog:isTemplateEdit,
            companyDetials:{}
        }
    }

    componentDidMount(){
        this.initData();
    }

    onDragEnd = (result) => {
        const {destination, source} = result;

        //Outside the droppable
        if(!destination){
            return
        }

        //if draggable is dropped in same droppable and in same position
        if(destination.droppableId === source.droppableId &&
        destination.index === source.index){
            return
        }

        let newPosition = destination.index;
        let oldPosition = source.index;
        let datalist = this.state.datalist;

        // console.log("newPosition:"+newPosition+" oldPosition:"+oldPosition);

        let fieldObj = datalist[oldPosition];
        datalist.splice(oldPosition,1)//remove from old position
        datalist.splice(newPosition,0,fieldObj);//move the field Obj to new pos

        let datalistNew = datalist.map((item,idx)=>{
            // console.log("Index:"+(idx+1)+" OrderPos:"+item.orderPos);
            let index = idx+1;
            if(index+"" !== item.orderPos+""){
                item.fieldStatus_new = item.fieldStatus_new==="Created"?"Created":"Updated";
            }
            item.orderPos = idx+1;
            return item;
        })

        this.setState({
            datalist:datalistNew
        })

    }

    //Left Side Dialog Functions
    handleSelectDropdownDialogShow = (item) => {
        this.setState({
            showSelectDropdownDialog:true,
            editTemplateItem:item
        })
    }

    handleSelectDropdownDialogClose = () => {
        this.setState({
            showSelectDropdownDialog:false,
            editTemplateItem:{}
        })
    }
    
    handleAddNewFieldClick = () => {
        let fieldNameArray = this.state.datalist.map((item)=>item.fieldName.trim());
        this.setState({
            showCreateDialog:true,
            addToPosition:"Right",
            fieldNameArray:fieldNameArray
        });
    }

    handleAddNewFieldToLeftClick = () => {
        this.setState({
            showCreateDialog:true,
            addToPosition:"Left"
        });
    }

    handleEditTemplateNameDialog = () => {
        this.setState({
            showAddTemplateNameDialog:true,
            isEditTemplateName:true
        });
    }

    handleEditTemplateNameDialogClose = (reloadFlag,templateName) => {
        if(reloadFlag){
            this.setState({
                showAddTemplateNameDialog:false,
                isEditTemplateName:false,
                templateName:templateName
            });
        }else{
            this.setState({
                showAddTemplateNameDialog:false,
                isEditTemplateName:false,
            });

        }
    }

    handleFieldItemEditIconClick = (templateItem,index,addToPosition) => {
        if(addToPosition === "Left"){
            let templateItem_prev = this.state.templateFieldsLeftMap[templateItem.fieldId];
            let fieldUsedInObs = this.isUsedInObs(templateItem.fieldId);

            this.setState({
                showCreateDialog:true,
                editTemplateItem:templateItem,
                editTemplateItem_prev:templateItem_prev,
                fieldUsedInObs:fieldUsedInObs,
                isEditField:true,
                deleteReactivateId:index,
                addToPosition:"Left"           
            });
        }else{
            let templateItem_prev = this.state.templateFieldsMap[templateItem.fieldId];
            let fieldUsedInObs = this.isUsedInObs(templateItem.fieldId);
            this.setState({
                showCreateDialog:true,
                editTemplateItem:templateItem,
                editTemplateItem_prev:templateItem_prev,
                fieldUsedInObs:fieldUsedInObs,
                isEditField:true,
                deleteReactivateId:index,
                addToPosition:"Right"           
            });
        }
    }
    //1
    handleFieldItemDeleteIconClick = (fieldItem,index,addToPosition) => {
        // e.stopPropagation();
        if(this.isUsedInAssignment()){
            //should not allow delete
            this.setState({
                showAlertDialogInfo:true,
                alertDialogMessageInfo:"Field cannot be deleted as the template is used in ongoing assignment.",
            });
        }else{
            //show Alert
            this.setState({
                addToPosition:addToPosition,
    
                operationType:"Delete",
                alertDialogHeading:"Delete Field",
                proceedBtnLabel:"Delete",
                deleteReactivateId:index,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to delete field - "+fieldItem.fieldName+"?"
            })
        }
    }
    //2
    handleRecordOperation = () => {
        if(this.state.operationType === "Delete"){
            this.handleDeleteFieldItemAlert();
        }else if(this.state.operationType === "Reactive"){
            this.handleReactivateFieldItem();
        }else if(this.state.operationType === "Discard"){
            this.discardAllTemplateChanges();
        }else if(this.state.operationType === "Discard Item Change"){
            this.discardItemChange();
        }else if(this.state.operationType === "Enable Field"){
            this.enableDisableRiskField("Y");
        }else if(this.state.operationType === "Disable Field"){
            this.enableDisableRiskField("N");
        }else if(this.state.operationType === "DisableFixed"){
            this.enableDisableFixedField("N");
        }else if(this.state.operationType === "EnableFixed"){
            this.enableDisableFixedField("Y");
        }
    }
    //3
    handleDeleteFieldItemAlert = () =>{
        let isUsedInObs = false;
        if(this.state.addToPosition === "Left"){
            let item = this.state.datalistLeft[this.state.deleteReactivateId]
            isUsedInObs = this.isUsedInObs(item.fieldId);
        }else if(this.state.addToPosition === "Right"){
            let item = this.state.datalist[this.state.deleteReactivateId]
            isUsedInObs = this.isUsedInObs(item.fieldId);
        }

        if(isUsedInObs){
            //need to show one more dialog just to confirm the delete
            this.setState({
                showSecondAlertDialog:true,
                secondAlertDialogMessage:"There is a data stored for the field. Data will also get deleted with the field. Are you sure?",
                secondAlertProceedBtnLabel:"Ok",
            })
        }else{
            this.handleDeleteFieldItem();
        }
    }
    //4
    handleDeleteFieldItem = () => {
        let datalistTemp = [];
        if(this.state.addToPosition === "Right"){
            datalistTemp = this.state.datalist;
            let item = this.state.datalist[this.state.deleteReactivateId];

            //check the previous status, if it is just created then hard delete it
            if(item.fieldStatus === "Created"){
                datalistTemp.splice(this.state.deleteReactivateId,1);
            }else{
                item.fieldStatus_new = "Deleted";
                datalistTemp.splice(this.state.deleteReactivateId,1,item);
            }

            this.setState({
                datalist:datalistTemp,
                deleteReactivateId:"",
                operationType:"",
                alertDialogHeading:"",
                proceedBtnLabel:"",
                alertDialogMessage:"",
                showAlertDialog:false,
                addToPosition:"",

                showSecondAlertDialog:false,
                secondAlertDialogMessage:"",
                secondAlertProceedBtnLabel:"",
            })
        }else if(this.state.addToPosition === "Left"){
            datalistTemp = this.state.datalistLeft;
            let item = this.state.datalistLeft[this.state.deleteReactivateId];

            //check the previous status, if it is just created then hard delete it
            if(item.fieldStatus === "Created"){
                datalistTemp.splice(this.state.deleteReactivateId,1);
            }else{
                item.fieldStatus_new = "Deleted";
                datalistTemp.splice(this.state.deleteReactivateId,1,item);
            }

            this.setState({
                datalistLeft:datalistTemp,
                deleteReactivateId:"",
                operationType:"",
                alertDialogHeading:"",
                proceedBtnLabel:"",
                alertDialogMessage:"",
                showAlertDialog:false,
                addToPosition:"",

                showSecondAlertDialog:false,
                secondAlertDialogMessage:"",
                secondAlertProceedBtnLabel:"",
            })
        }
    }

    enableDisableRiskField = (flag) => {
        let datalistTemp = this.state.datalist;
        let item = this.state.datalist[this.state.deleteReactivateId];
        item.isRiskMasterEnabled = flag;
        item.fieldStatus_new = item.fieldStatus_new==="Created"?"Created":"Updated";
        datalistTemp.splice(this.state.deleteReactivateId,1,item);

        this.setState({
            datalist:datalistTemp,
            deleteReactivateId:"",
            operationType:"",
            alertDialogHeading:"",
            proceedBtnLabel:"",
            alertDialogMessage:"",
            showAlertDialog:false,
            addToPosition:"",
        })

    }

    enableDisableFixedFieldsAlert = (type) => {
        if(isDetails){
            return;
        }
        let fixedTemplateFields = this.state.fixedTemplateFields;

        this.setState({
            operationType:fixedTemplateFields[type] === "Y"?"DisableFixed":"EnableFixed",
            alertDialogHeading:fixedTemplateFields[type] === "Y"?"Disable Field":"Enable Field",
            proceedBtnLabel:fixedTemplateFields[type] === "Y"?"Disable":"Enable",
            deleteReactivateId:type,
            showAlertDialog:true,
            alertDialogMessage:fixedTemplateFields[type] === "Y"?
                                "Are you sure you want to disable this field?":
                                "Are you sure you want to enable this field?"
        })
    }

    enableDisableFixedField = (flag) => {
        let fixedTemplateFields = this.state.fixedTemplateFields;
        fixedTemplateFields[this.state.deleteReactivateId] = flag;
        fixedTemplateFields[this.state.deleteReactivateId+"Status"] = fixedTemplateFields[this.state.deleteReactivateId+"Status"]==="Created"?"Created":"Updated";

        this.setState({
            fixedTemplateFields:fixedTemplateFields,
            deleteReactivateId:"",
            operationType:"",
            alertDialogHeading:"",
            proceedBtnLabel:"",
            alertDialogMessage:"",
            showAlertDialog:false,
            addToPosition:"",
        })
    }

    handleFieldItemReactivateIconClick = (fieldName,index,addToPosition) => {
        // e.stopPropagation();
        this.setState({
            addToPosition:addToPosition,

            operationType:"Reactive",
            alertDialogHeading:"Reactivate Field",
            proceedBtnLabel:"Reactivate",
            deleteReactivateId:index,
            showAlertDialog:true,
            alertDialogMessage:"Are you sure you want to reactivate field - "+fieldName+"?"
        })
    }

    handleFieldItemDiscardChangesIconClick = (templateItem,index,addToPosition) => {
        // e.stopPropagation();

        let templateItem_prev = {};
        try{
            if(addToPosition === "Left"){
                templateItem_prev = JSON.parse(JSON.stringify(this.state.templateFieldsLeftMap[templateItem.fieldId]));
            }else if(addToPosition === "Right"){
                templateItem_prev = JSON.parse(JSON.stringify(this.state.templateFieldsMap[templateItem.fieldId]));
            }
        }catch(e){
            templateItem_prev = {};
        }

        this.setState({
            addToPosition:addToPosition,

            operationType:"Discard Item Change",
            alertDialogHeading:"Discard Change",
            proceedBtnLabel:"Discard",
            deleteReactivateId:index,
            showAlertDialog:true,
            alertDialogMessage:"Are you sure you want to discard changes to field - "+templateItem.fieldName+"?",

            editTemplateItem:templateItem,
            editTemplateItem_prev:templateItem_prev,
        })
    }

    discardItemChange = () => {
        let datalistTemp = [];
        if(this.state.addToPosition === "Right"){
            datalistTemp = this.state.datalist;
            datalistTemp.splice(this.state.deleteReactivateId,1,this.state.editTemplateItem_prev);
            this.setState({
                datalist:datalistTemp,
                deleteReactivateId:"",
                operationType:"",
                alertDialogHeading:"",
                proceedBtnLabel:"",
                alertDialogMessage:"",
                showAlertDialog:false,
                addToPosition:"",
                editTemplateItem:{},
                editTemplateItem_prev:{}
            });
        }else if(this.state.addToPosition === "Left"){
            datalistTemp = this.state.datalistLeft;
            datalistTemp.splice(this.state.deleteReactivateId,1,this.state.editTemplateItem_prev);
    
    
            this.setState({
                // datalist:datalistTemp,
                datalistLeft:datalistTemp,
    
                deleteReactivateId:"",
                operationType:"",
                alertDialogHeading:"",
                proceedBtnLabel:"",
                alertDialogMessage:"",
                showAlertDialog:false,
                addToPosition:"",
                editTemplateItem:{},
                editTemplateItem_prev:{}
            });
        }
    }

    handleReactivateFieldItem = () =>{
        let datalistTemp = [];
        if(this.state.addToPosition === "Right"){
            datalistTemp = this.state.datalist;
            let item = this.state.datalist[this.state.deleteReactivateId];
            item.fieldStatus_new = item.fieldStatus;
            datalistTemp.splice(this.state.deleteReactivateId,1,item);
            this.setState({
                datalist:datalistTemp,
                deleteReactivateId:"",
                operationType:"",
                alertDialogHeading:"",
                proceedBtnLabel:"",
                alertDialogMessage:"",
                showAlertDialog:false,
                addToPosition:"",
            })
        }else if(this.state.addToPosition === "Left"){
            datalistTemp = this.state.datalistLeft;
            let item = this.state.datalistLeft[this.state.deleteReactivateId];
            item.fieldStatus_new = item.fieldStatus;
            datalistTemp.splice(this.state.deleteReactivateId,1,item);
            this.setState({
                datalistLeft:datalistTemp,
                deleteReactivateId:"",
                operationType:"",
                alertDialogHeading:"",
                proceedBtnLabel:"",
                alertDialogMessage:"",
                showAlertDialog:false,
                addToPosition:"",
            })
        }
    }

    // handleEditTemplateItemClick = (templateItem,index) => {
    //     this.setState({
    //         showCreateDialog:true,
    //         editTemplateItem:templateItem,
    //         isEditField:true,
    //         deleteReactivateId:index,
    //         addToPosition:"Right"           
    //     });
    // }

    // handleEditTemplateLeftItemClick = (templateItem,index) => {
    //     this.setState({
    //         showCreateDialog:true,
    //         editTemplateItem:templateItem,
    //         isEditField:true,
    //         deleteReactivateId:index,
    //         addToPosition:"Left"           
    //     });
    // }

    handleCreateDialogClose = (fieldObj) => {

        let datalistTemp = [];
        if(this.state.addToPosition === "Right"){
            datalistTemp= this.state.datalist;
        }else if(this.state.addToPosition === "Left"){
            datalistTemp= this.state.datalistLeft;
        }
        if(fieldObj){
            let obsFieldCount =this.state.obsFieldCount;
            if(this.state.isEditField){
                fieldObj.orderPos = datalistTemp[this.state.deleteReactivateId].orderPos;
                datalistTemp.splice(this.state.deleteReactivateId,1, fieldObj);
            }else{
                
                fieldObj.fieldId = ++obsFieldCount +"";
                fieldObj.orderPos = datalistTemp.length+1;
                datalistTemp.push(fieldObj);
            }

            if(this.state.addToPosition === "Right"){
                // showTasks=datalistTemp.some((item) =>item.fieldName===fieldObj.fieldName);

                this.setState({
                    showCreateDialog:false,
                    isEditField:false,
                    datalist:datalistTemp,
                    editTemplateItem:{},
                    editTemplateItem_prev:{},
                    fieldUsedInObs:false,
                    deleteReactivateId:"",
                    addToPosition:"",
                    obsFieldCount:obsFieldCount,
                    fieldNameArray:[]
                });
            }else if(this.state.addToPosition === "Left"){
                this.setState({
                    showCreateDialog:false,
                    isEditField:false,
                    datalistLeft:datalistTemp,
                    editTemplateItem:{},
                    editTemplateItem_prev:{},
                    fieldUsedInObs:false,
                    deleteReactivateId:"",
                    addToPosition:"",
                    obsFieldCount:obsFieldCount
                });
            }
            
        }else{
            this.setState({
                showCreateDialog:false,
                isEditField:false,
                editTemplateItem:{},
                editTemplateItem_prev:{},
                deleteReactivateId:"",
                addToPosition:"",
            });
        }
    }

    modules = {
        toolbar: [
            [{ 'header': [1, 2, 3, false] }],
            ['bold', 'italic', 'underline','strike',{ 'color': [] }, { 'background': [] }],
            [{'list': 'ordered'}, {'list': 'bullet'},{ 'align': [] },'link'],
            ['clean']
        ],
    }

    handleAlertDialogClose = () =>{
        this.setState({
            showAlertDialog:false,
            alertDialogMessage:"",
            deleteReactivateId:"",
            operationType:"",
            alertDialogHeading:"",
        });
    }

    handleSecondAlertDialogClose = () =>{
        this.setState({
            showSecondAlertDialog:false,
            secondAlertDialogMessage:"",
            secondAlertProceedBtnLabel:"",
        });
    }

    handleAlertDialogCloseInfo = () =>{
        this.setState({
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        });
    }
    
    onCancelClick = () => {
        this.props.history.push(Constants.URL_OBSERVATION_TEMPLATES);
    }

    isUsedInAssignment = () => {
        //since we are creating versions if it is used in the assignment
        //there is no need to check this. lets hard code it to false
        // return this.state.assignmentCount>0?true:false;
        return false;
    }

    isUsedInObs = (fieldId) => {
        let fieldUsageItem = this.state.fieldUseCountMap[fieldId];
        
        if(isEmptyVariable(fieldUsageItem)){
            return false;
        }

        let count = 0;
        try{
            count = parseInt(fieldUsageItem.cnt);
        }catch(e){
            count = 0;
        }

        if(count > 0){
            return true;
        }
        
        return false;
    }

    onTableDropDownItemClick = (item,dropdownItem,index,addToPosition) =>{
        if(item.label === "Edit"){
            this.handleFieldItemEditIconClick(dropdownItem,index,addToPosition);
        }else if(item.label === "Delete"){
            this.handleFieldItemDeleteIconClick(dropdownItem,index,addToPosition);
        }else if(item.label === "Discard Changes" && dropdownItem.fieldStatus_new === "Deleted"){
            this.handleFieldItemReactivateIconClick(dropdownItem.fieldName,index,addToPosition);
        }else if(item.label === "Discard Changes" && dropdownItem.fieldStatus_new === "Updated"){
            this.handleFieldItemDiscardChangesIconClick(dropdownItem,index,addToPosition);
        }else if(item.label === "Show Differences"){
            this.setState({
                showDifferenceDialog:true,
            })
        }else if(item.label === "Enable Field"){
            this.enableDisableFieldAlert(dropdownItem,index);
        }else if(item.label === "Disable Field"){
            this.enableDisableFieldAlert(dropdownItem,index);
        }
    }

    enableDisableFieldAlert = (fieldItem,index) => {
        let message = "Are you sure you want to disable field - "+fieldItem.fieldName+"?";
        let label = "Disable";
        let operationType = "Disable Field";
        if(fieldItem.isRiskMasterEnabled === "N"){
            message = "Are you sure you want to enable field - "+fieldItem.fieldName+"?";
            label = "Enable";
            operationType = "Enable Field";
        }
        this.setState({
            operationType:operationType,
            alertDialogHeading:operationType,
            proceedBtnLabel:label,
            deleteReactivateId:index,
            showAlertDialog:true,
            alertDialogMessage:message
        })
    }

    getFieldActionArray = (canBeDeleted, fieldStatus_new, isFromRiskMaster, isRiskMasterEnabled) => {
        let actionObj = {};
        let actionArr = [];
        let dropdownWidth = "140px";
        
        actionArr.push(editDropdownObj);
        if(canBeDeleted === "Y" && fieldStatus_new !== "Deleted"){
            actionArr.push(delDropdownObj);
        }else if(canBeDeleted === "Y" && fieldStatus_new === "Deleted"){
            dropdownWidth = "170px";
            actionArr.push(discardChangesObj);
        }

        if(isFromRiskMaster){
            if(isRiskMasterEnabled === "N"){
                actionArr.push(enableDropdownObj);
            }else{
                actionArr.push(disableDropdownObj);
            }
        }


        if(fieldStatus_new === "Updated"){
            dropdownWidth = "170px";
            actionArr.push(discardChangesObj);
            actionArr.push(differenceDropdownObj);
        }
        actionObj = {
            actionArr: actionArr,
            dropdownWidth: dropdownWidth,
        };

        return actionObj;
    }

    handleDifferenceDialogClose = () =>{
        this.setState({
            showDifferenceDialog:false
        })
    }

    handleSelectAssignmentDialogClose = () => {
        this.setState({
            showSelectAssignmentDialog:false,
            showLoader:false
        })
    }

    returnSelectedAssignmentIds = (selectedIds) => {
        let postParams = {
            templateId:this.state.templateId,
            email:userDetails.email,
            accessToken:userDetails.accessToken,
            templateName:this.state.templateName,
            templateFields:JSON.stringify(this.state.datalist),
            templateFieldsMaxId:this.state.obsFieldCount,
            templateFieldsLeft:JSON.stringify(this.state.datalistLeft),
            assignmentIds:JSON.stringify(selectedIds),
            fixedTemplateFields:JSON.stringify(this.state.fixedTemplateFields)
        }
        this.handleSaveTemplate(Constants.UpdateTemplate,postParams);
        
    }
    /************************API CALLS****************************/
    initData = () => {
        if(this.state.isEdit){
            
            Promise.all([
                fetch(Constants.GetTemplates, {
                    method: "POST",
                    mode: "cors",
                    body: new URLSearchParams({
                        email: userDetails.email,
                        accessToken: userDetails.accessToken,
                        templateType:TemplateConstants.TEMPLATE_TYPE_OBSERVATION,
                        templateId:this.state.templateId,
                    }),
                }),
                fetch(Constants.GetUserMenu, {
                    method: "POST",
                    mode: "cors",
                    body: new URLSearchParams({
                        email: userDetails.email,
                        accessToken: userDetails.accessToken,
                    }),
                }),
                fetch(Constants.GetOtherMasters,
                {
                    method: "POST",
                    mode:'cors',
                    body: new URLSearchParams({
                        email:userDetails.email,
                        accessToken:userDetails.accessToken,
                        masterType:"Risk",
                        pageNo:1,
                        resultsize:100,
                        status:"Active"
                    })
                })
            ])
            .then(([generalRes, menuRes,masterRes]) => {
                return Promise.all([generalRes.json(), menuRes.json(), masterRes.json()]);
            })
            .then(([generalRes, menuRes,masterRes]) => {

                let menusTemp = {};
                let businessRisks = [];
                let businessRiskArr = [];

                let datalist = [];
                let datalistLeft = [];
                let fixedTemplateFields = [];
                let fixedTemplateFields_prev = "{}";

                let templateObj = {};
                let templateName = "";
                let templateId = "";
                let obsFieldCount = 0;
                let templateFieldsMap = {};
                let templateFields_prev = "[]";
                let templateFieldsLeftMap = {};
                let templateFieldsLeft_prev = "[]";
                let fieldUseCountMap = {};
                let assignmentCount = 0;
                let updateAssignmentIds = [];
                let companyDetialsTemp={};


                if (generalRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || generalRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
                    localStorage.clear();
                    window.location = "/";
                } else if (generalRes.responseCode === Constants.CODE_SUCCESS) {
                    templateObj = generalRes.data.result[0] || {};
                    if(!isEmptyVariable(templateObj.templateFieldsNew) && isJsonString(templateObj.templateFieldsNew)){
                        datalist = JSON.parse(templateObj.templateFieldsNew);
                    }
                    if(!isEmptyVariable(templateObj.templateFieldsLeftNew) && isJsonString(templateObj.templateFieldsLeftNew)){
                        datalistLeft = JSON.parse(templateObj.templateFieldsLeftNew);
                    }
                    console.log(templateObj,"::obj")
                    if(!isEmptyArray(Object.keys(templateObj)) && (!isEmptyVariable(templateObj?.fixedTemplateFieldsNew) || !isEmptyArray(Object.keys(templateObj?.fixedTemplateFieldsNew))) && isJsonString(templateObj?.fixedTemplateFieldsNew)){
                        console.log("::::if")
                        fixedTemplateFields = JSON.parse(templateObj?.fixedTemplateFieldsNew);
                    }else{
                        console.log("::hi")
                        fixedTemplateFields = TemplateConstants.OBSERVATION_FIXED_FIELDS;
                    }

                    if(!isEmptyVariable(templateObj.templateFields) && isJsonString(templateObj.templateFields)){
                        templateFields_prev = templateObj.templateFields;
                        let templateFields = JSON.parse(templateObj.templateFields);
                        templateFields.forEach(item=>{
                            templateFieldsMap[item.fieldId] = item;
                        })
                    }
                    if(!isEmptyVariable(templateObj.templateFieldsLeft) && isJsonString(templateObj.templateFieldsLeft)){
                        templateFieldsLeft_prev = templateObj.templateFieldsLeft;
                        let templateFieldsLeft = JSON.parse(templateObj.templateFieldsLeft);
                        templateFieldsLeft.forEach(item=>{
                            templateFieldsLeftMap[item.fieldId] = item;
                        })
                    }

                    if(!isEmptyVariable(templateObj.fixedTemplateFieldsNew) && isJsonString(templateObj.fixedTemplateFieldsNew)){
                        fixedTemplateFields_prev = templateObj.fixedTemplateFieldsNew;
                    }
                    
                    templateName = templateObj.templateName;
                    templateId = templateObj.templateId;
                    obsFieldCount = templateObj.templateFieldsMaxId;
                    try{
                        assignmentCount = parseInt(templateObj.assignmentCount);
                    }catch(e){
                        assignmentCount = 0;
                    }

                    let updateAssignmentIdsStr = templateObj.updateAssignmentIds;
                    if(!isEmptyVariable(updateAssignmentIdsStr) && isJsonString(updateAssignmentIdsStr)){
                        updateAssignmentIds = JSON.parse(updateAssignmentIdsStr);
                    }

                    let fieldUseCount = generalRes.data.fieldUseCount;
                    //lets create a field usage map
                    !isEmptyArray(fieldUseCount) &&
                    fieldUseCount.forEach(item=>{
                        fieldUseCountMap[item.fieldId+""] = item;
                    })

                } else {
                }
                
                if (menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
                    localStorage.clear();
                    window.location = "/";
                } else if (menuRes.responseCode === Constants.CODE_SUCCESS) {
                    menusTemp = menuRes.data.menus;
                    companyDetialsTemp = menuRes.data.company
                } else {
                }
                
                if (masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
                    localStorage.clear();
                    window.location = "/";
                } else if (masterRes.responseCode === Constants.CODE_SUCCESS) {
                    
                    let businessRiskObj = {
                        businessRiskId:-1,
                        shortName:"",
                        name:"Other",
                        description:""
                    }
                    businessRisks = masterRes.data.result;
                    businessRisks.push(businessRiskObj);
                    
                    //add property "displayname: name (description)" to businessRisk array
                    businessRisks.forEach(item=>{
                        if(item.name !=="Other"){
                            item.value = item.name+(!isEmptyVariable(item.description)?": "+item.description:"");
                        }
                        else{
                            item.value = item.name
                        }
                        businessRiskArr.push(item);
                    });

                } else {
                }
                let menusTemp2 = GetTemplateTabMenu(menusTemp);
                
                this.setState({
                    componentDidMountFlag: true,
                    showLoader: false,
                    datalist: datalist,
                    templateFieldsMap: templateFieldsMap,
                    templateFields_prev: templateFields_prev,

                    datalistLeft: datalistLeft,
                    templateFieldsLeftMap: templateFieldsLeftMap,
                    templateFieldsLeft_prev: templateFieldsLeft_prev,
                    
                    fixedTemplateFields:fixedTemplateFields,
                    fixedTemplateFields_prev:fixedTemplateFields_prev,

                    templateName:templateName,
                    templateNameOld:templateName,
                    templateId:templateId,
                    obsFieldCount:obsFieldCount,
                    menus: menusTemp2,
                    updateAssignmentIds:updateAssignmentIds,
                    businessRisk:businessRiskArr,
                    assignmentCount:assignmentCount,
                    fieldUseCountMap:fieldUseCountMap,
                    companyDetials:companyDetialsTemp
                });
            });
        
        }else{

            Promise.all([
                fetch(Constants.GetUserMenu, {
                    method: "POST",
                    mode: "cors",
                    body: new URLSearchParams({
                        email: userDetails.email,
                        accessToken: userDetails.accessToken,
                    }),
                }),
                fetch(Constants.GetOtherMasters,
                {
                    method: "POST",
                    mode:'cors',
                    body: new URLSearchParams({
                        email:userDetails.email,
                        accessToken:userDetails.accessToken,
                        masterType:"Risk",
                        pageNo:1,
                        resultsize:100,
                        status:"Active"
                    })
                })
            ])
            .then(([ menuRes,masterRes]) => {
                return Promise.all([menuRes.json(), masterRes.json()]);
            })
            .then(([ menuRes,masterRes]) => {

                let menusTemp = {};
                let businessRisks = [];
                let businessRiskArr = [];
                let datalist = [];
                let datalistLeft = [];
                let templateName = "";
                let obsFieldCount = 0;
                let fixedTemplateFields = [];

                // if (generalRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || generalRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
                //     localStorage.clear();
                //     window.location = "/";
                // } else if (generalRes.responseCode === Constants.CODE_SUCCESS) {
                //     templateObj = generalRes.data.result[0];
                // } else {
                // }
                
                //add Template Fields from TemplateConstants
                datalist = TemplateConstants.OBSERVATION_FIELDS;
                obsFieldCount = TemplateConstants.OBSERVATION_MAX_ID;
                templateName = templateNamePlaceholder;
                fixedTemplateFields = TemplateConstants.OBSERVATION_FIXED_FIELDS;

                if (menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
                    localStorage.clear();
                    window.location = "/";
                } else if (menuRes.responseCode === Constants.CODE_SUCCESS) {
                    menusTemp = menuRes.data.menus;
                    
                    

                } else {
                }
                if (masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
                    localStorage.clear();
                    window.location = "/";
                } else if (masterRes.responseCode === Constants.CODE_SUCCESS) {
                    
                    let businessRiskObj = {
                        businessRiskId:-1,
                        shortName:"",
                        name:"Other",
                        description:""
                    }
                    businessRisks = masterRes.data.result;
                    businessRisks.push(businessRiskObj);
                    
                    //add property "displayname: name (description)" to businessRisk array
                    businessRisks.forEach(item=>{
                        if(item.name !=="Other"){
                            item.value = item.name+" ("+item.description+")"
                        }
                        else{
                            item.value = item.name
                        }
                        businessRiskArr.push(item);
                    });

                } else {
                }
                let menusTemp2 = GetTemplateTabMenu(menusTemp);

                this.setState({
                    componentDidMountFlag: true,
                    showLoader: false,
                    datalist: datalist,
                    datalistLeft: datalistLeft,
                    templateName:templateName,
                    fixedTemplateFields:fixedTemplateFields,
                    // templateId:templateId,
                    obsFieldCount:obsFieldCount,
                    menus: menusTemp2,
                    businessRisk:businessRiskArr,
                });
            });
        }
    }

    checkTemplateVersionCreation = () => {
        fetch(Constants.CheckTemplateVersionCreation,{
            method:"POST",
            mode: "cors",
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                templateId:this.state.templateId,
                templateName:this.state.templateName,
                templateFields:JSON.stringify(this.state.datalist),
                templateFieldsLeft:JSON.stringify(this.state.datalistLeft),
                templateFieldsMaxId:this.state.obsFieldCount,
                assignmentIds:JSON.stringify(this.state.updateAssignmentIds),
                fixedTemplateFields:JSON.stringify(this.state.fixedTemplateFields)
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                let isAssignmentListNeeded = data.isAssignmentListNeeded;
                if(isAssignmentListNeeded === "Y"){
                    this.checkOngoingAssignments();
                }else{
                    this.props.history.push(Constants.URL_OBSERVATION_TEMPLATES);
                }
            }else{
                this.setState({
                    showAlertDialogInfo:true,
                    alertDialogMessageInfo:data.responseMessage,
                    showLoader:false,
                });
            }
        });
    }

    checkOngoingAssignments = () => {
        fetch(Constants.getOngoingAssignments,{
            method:"POST",
            mode: "cors",
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                templateId:this.state.templateId,
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                let assignments = data.data.assignments;
                if(isEmptyArray(assignments)){
                    let postParams = {
                        templateId:this.state.templateId,
                        email:userDetails.email,
                        accessToken:userDetails.accessToken,
                        templateName:this.state.templateName,
                        templateFields:JSON.stringify(this.state.datalist),
                        templateFieldsMaxId:this.state.obsFieldCount,
                        templateFieldsLeft:JSON.stringify(this.state.datalistLeft),
                        assignmentIds:JSON.stringify(this.state.updateAssignmentIds),
                        fixedTemplateFields:JSON.stringify(this.state.fixedTemplateFields)
                    }
                    this.handleSaveTemplate(Constants.UpdateTemplate,postParams);
                }else{
                    //show the list of ongoing assignments
                    this.setState({
                        assignments:assignments,
                        showSelectAssignmentDialog:true
                    })
                }
            }else{
                this.setState({
                    showAlertDialogInfo:true,
                    alertDialogMessageInfo:data.responseMessage,
                    showLoader:false,
                });
            }
        });
    }

    handleSaveTemplateValidation = () => {
        this.setState({
            showLoader:true,
        });

        let url = "";
        let postParams = {
            email:userDetails.email,
            accessToken:userDetails.accessToken,
            templateName:this.state.templateName,
            templateFields:JSON.stringify(this.state.datalist),
            templateFieldsMaxId:this.state.obsFieldCount,
            templateFieldsLeft:JSON.stringify(this.state.datalistLeft),
            assignmentIds:JSON.stringify(this.state.updateAssignmentIds),
            fixedTemplateFields:JSON.stringify(this.state.fixedTemplateFields)
        };

        let callApi = false;
        let callApiLeft = false;
        let callApiNameChange = false;
        let callApiFixedFields = false;

        if(this.state.isEdit){
            postParams.templateId = this.state.templateId;
            url = Constants.UpdateTemplate;

            callApiNameChange  = this.state.templateName !== this.state.templateNameOld;

            //before we call update template we need to check if anything is changed
            callApi = this.state.datalist.some(item => (item.fieldStatus_new === "Created" || 
            item.fieldStatus_new === "Deleted" || item.fieldStatus_new === "Updated"));

            callApiLeft = this.state.datalistLeft.some(item => (item.fieldStatus_new === "Created" || 
            item.fieldStatus_new === "Deleted" || item.fieldStatus_new === "Updated"));

            let fixedTemplateFields = this.state.fixedTemplateFields;
            callApiFixedFields = fixedTemplateFields.assignRiskStatus === "Updated" || 
                                    fixedTemplateFields.valueAdditionStatus === "Updated" || 
                                    fixedTemplateFields.annexuresStatus === "Updated" || 
                                    fixedTemplateFields.backupsStatus === "Updated" ||
                                    fixedTemplateFields.assignRiskStatus === "Created" || 
                                    fixedTemplateFields.valueAdditionStatus === "Created" || 
                                    fixedTemplateFields.annexuresStatus === "Created" || 
                                    fixedTemplateFields.backupsStatus === "Created";
            
            if(callApi || callApiLeft || callApiNameChange || callApiFixedFields){
                this.checkTemplateVersionCreation();
            }else{
                this.props.history.push(Constants.URL_OBSERVATION_TEMPLATES);
            }
            
        }else{
            url = Constants.CreateTemplate;
            
            postParams.departmentId = this.state.departmentId;
            postParams.templateType = TemplateConstants.TEMPLATE_TYPE_OBSERVATION;

            this.handleSaveTemplate(url,postParams);
        }
    }

    handleSaveTemplate = (url,postParams) => {
        fetch(url,{
            method:"POST",
            mode: "cors",
            body: new URLSearchParams(postParams)
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.props.history.push(Constants.URL_OBSERVATION_TEMPLATES);
            }else{
                this.setState({
                    showAlertDialogInfo:true,
                    alertDialogMessageInfo:data.responseMessage,
                    showLoader:false,
                });
            }
        });
    }

    handleTemplateAlertDialogClose = () =>{
        this.setState({
            showTemplateAlertDialog:false
        })
    }
    handleTemplateInfoClose = () => {
        this.setState({
            showTemplateInfoDialog:false
        })
    }

    render(){
        let fields = {text:"value",value:"value"};
        let fieldsWithDesc = {text:"valueNdesc",value:"value"};
        return(
            <Router>
                <main className="main-dashboard">
                    <section className="dashboard-bg">
                        <Sidebar />
                        <div className="dashboard-right-bg-collpase">
                            <div className="dashboard-wrapper">
                                <Topbar />
                                <MasterMenuTabs 
                                    masterMenus={this.state.menus}
                                    themeSettings={themeSettings}
                                />
                                {/* <div className="flex-center-layout m-t-10">
                                    <h6>Observation Template</h6>
                                </div> */}
                                {
                                    // Need to check component did mount flag, other wise until api is called,
                                    // it shows no items found layout.
                                    this.state.componentDidMountFlag &&
                                    <div className="m-t-10">
                                        <div className="row custom-row">
                                        <div className="col-md-3 custom-col m-t-10">
                                                <TaskTagButtonsLayout
                                                themeSettings={themeSettings}
                                                >
                                                    <ul>
                                                        <li>
                                                            <a href="javascript:void(0)" >
                                                                Tag Tasks
                                                            </a>
                                                        </li>
                                                        <li className="flex-center-layout">
                                                            <a href="javascript:void(0)" style={{color:this.state.fixedTemplateFields.assignRisk === "Y"?"#000":"#aaa"}}>
                                                                Assign Risk
                                                                {
                                                                    this.state.fixedTemplateFields.assignRiskStatus === "Updated" &&
                                                                    <span className="span-tag-fixed">
                                                                        Updated
                                                                    </span>
                                                                }

                                                                {
                                                                    this.state.fixedTemplateFields.assignRisk !== "Y" &&
                                                                    <span className="span-tag-fixed">
                                                                        Disabled
                                                                    </span>
                                                                }

                                                            </a>
                                                            <span 
                                                            onClick={this.enableDisableFixedFieldsAlert.bind(this,"assignRisk")}
                                                            className={"material-icons enable-disable-span "+(this.state.fixedTemplateFields.assignRisk === "Y"?"enabled":"disabled")}>
                                                            {
                                                                this.state.fixedTemplateFields.assignRisk === "Y"?enableDropdownObj.icon:disableDropdownObj.icon
                                                            }
                                                            </span>
                                                        </li>
                                                        <li className="flex-center-layout">
                                                            <a href="javascript:void(0)" style={{color:this.state.fixedTemplateFields.valueAddition === "Y"?"#000":"#aaa"}}>
                                                                Value Addition
                                                                {
                                                                    this.state.fixedTemplateFields.valueAdditionStatus === "Updated" &&
                                                                    <span className="span-tag-fixed">
                                                                        Updated
                                                                    </span>
                                                                }

                                                                {
                                                                    this.state.fixedTemplateFields.valueAddition !== "Y" &&
                                                                    <span className="span-tag-fixed">
                                                                        Disabled
                                                                    </span>
                                                                }

                                                            </a>
                                                            <span 
                                                            onClick={this.enableDisableFixedFieldsAlert.bind(this,"valueAddition")}
                                                            className={"material-icons enable-disable-span "+(this.state.fixedTemplateFields.valueAddition === "Y"?"enabled":"disabled")}>
                                                            {
                                                                this.state.fixedTemplateFields.valueAddition === "Y"?enableDropdownObj.icon:disableDropdownObj.icon
                                                            }
                                                            </span>
                                                        </li>
                                                        <li className="flex-center-layout">
                                                            <a href="javascript:void(0)" style={{color:this.state.fixedTemplateFields.annexures === "Y"?"#000":"#aaa"}}>
                                                                Upload Annexure
                                                                {
                                                                    this.state.fixedTemplateFields.annexuresStatus === "Updated" &&
                                                                    <span className="span-tag-fixed">
                                                                        Updated
                                                                    </span>
                                                                }

                                                                {
                                                                    this.state.fixedTemplateFields.annexures !== "Y" &&
                                                                    <span className="span-tag-fixed">
                                                                        Disabled
                                                                    </span>
                                                                }

                                                            </a>
                                                            <span 
                                                            onClick={this.enableDisableFixedFieldsAlert.bind(this,"annexures")}
                                                            className={"material-icons enable-disable-span "+(this.state.fixedTemplateFields.annexures === "Y"?"enabled":"disabled")}>
                                                            {
                                                                this.state.fixedTemplateFields.annexures === "Y"?enableDropdownObj.icon:disableDropdownObj.icon
                                                            }
                                                            </span>
                                                        </li>
                                                        <li className="flex-center-layout">
                                                            <a href="javascript:void(0)" style={{color:this.state.fixedTemplateFields.backups === "Y"?"#000":"#aaa"}}>
                                                                Upload Backup
                                                                {
                                                                    this.state.fixedTemplateFields.backupsStatus === "Updated" &&
                                                                    <span className="span-tag-fixed">
                                                                        Updated
                                                                    </span>
                                                                }

                                                                {
                                                                    this.state.fixedTemplateFields.backups !== "Y" &&
                                                                    <span className="span-tag-fixed">
                                                                        Disabled
                                                                    </span>
                                                                }

                                                            </a>
                                                            <span 
                                                            onClick={this.enableDisableFixedFieldsAlert.bind(this,"backups")}
                                                            className={"material-icons enable-disable-span "+(this.state.fixedTemplateFields.backups === "Y"?"enabled":"disabled")}>
                                                            {
                                                                this.state.fixedTemplateFields.backups === "Y"?enableDropdownObj.icon:disableDropdownObj.icon
                                                            }
                                                            </span>
                                                        </li>
                                                            {
                                                                this.state.datalistLeft.map((item,index)=>{
                                                                    let actionObj = this.getFieldActionArray(item.canBeDeleted, item.fieldStatus_new);
                                                                    let spanObj = getStatusSpan(item.fieldStatus_new,themeSettings);
                                                                    return  <li>
                                                                        <div class="template-field-left-layout m-b-10">
                                                                            <p className="m-b-5 flex-center-layout" style={{marginBottom:10}}
                                                                                onClick={this.handleSelectDropdownDialogShow.bind(this,item)}
                                                                            >
                                                                                {item.fieldName}{item.isMandatorySelected === "Y"?"*":""}
                                                                                {
                                                                                    !isEmptyVariable(spanObj.spanText) &&
                                                                                    <span
                                                                                    className="span-tag"
                                                                                    style={spanObj.spanStyle}
                                                                                    >{spanObj.spanText}</span>
                                                                                }
                                                                            </p>
                                                                            {
                                                                                !isDetails &&
                                                                                <div>
                                                                                    <TableDropDown
                                                                                        actionArr={actionObj.actionArr}
                                                                                        onDropDownItemClick={this.onTableDropDownItemClick}
                                                                                        dropDownId={item}
                                                                                        dropdownWidth={actionObj.dropdownWidth}
                                                                                        themeSettings={themeSettings}
                                                                                        udf1 = {index}
                                                                                        udf2 = {"Left"}
                                                                                    />
                                                                                    
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </li>
                                                                })
                                                            }
                                                            {
                                                                !isDetails &&
                                                                <div className="add-more-btn-layout" onClick={this.handleAddNewFieldToLeftClick}>
                                                                    <span className="material-icons">add_circle</span>
                                                                    <p>Add Field</p>
                                                                </div>
                                                            }
                                                    </ul>
                                                </TaskTagButtonsLayout>
                                            </div>
                                            <DragDropContext onDragEnd={this.onDragEnd}>
                                            <div className="col-md-9 custom-col add-template">
                                                {this.state.showTemplateInfoDialog && this.state.isEdit &&
                                                    <AlertInfo themeSettings={themeSettings}>
                                                        <span className="material-icons info-icon" >info</span>
                                                        <p className="flex-center-layout just-content">
                                                            <p>
                                                                <strong>Important:</strong> {templateAlertDialogMessage}
                                                            </p>
                                                            <span className="material-icons close-icon" onClick={this.handleTemplateInfoClose}>close</span>
                                                        </p>
                                                    </AlertInfo>
                                                }
                                                {   
                                                    <div className="flex-center-layout just-content">

                                                        <p className="m-b-0"><strong>{this.state.templateName}</strong></p>
                                                        {
                                                            !isDetails &&
                                                            <span className="material-icons template-action-icons m-b-0"
                                                                onClick={this.handleEditTemplateNameDialog}
                                                                >
                                                                edit
                                                            </span>
                                                        }
                                                    </div>
                                                }
                                                {
                                                    <Droppable droppableId={"RightFields"}>
                                                    {(provided)=>(
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.droppableProps}
                                                        >
                                                        {
                                                            this.state.datalist.map((item,index)=>{
                                                                let actionObj = this.getFieldActionArray(item.canBeDeleted, item.fieldStatus_new, 
                                                                    item.isFromRiskMaster, item.isRiskMasterEnabled);
                                                                let spanObj = getStatusSpan(item.fieldStatus_new,themeSettings)
                                                                return  <Draggable draggableId={item.fieldId+""} index={index} key={item.fieldId+""}>
                                                                    {(provided,snapshot)=>(
                                                                        <div class="template-field-layout"
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        ref={provided.innerRef}
                                                                        >
                                                                            {/* Helooooo */}
                                                                            <div className="editor-card" style={{flexGrow:1}}>
                                                                                <div className="task-header-layout">
                                                                                    <h6 className="editor-heading m-b-0 flex-center-layout" style={{marginBottom:10}}>
                                                                                        {item.fieldName}{item.isMandatorySelected === "Y"?"*":""}
                                                                                        {
                                                                                            !isEmptyVariable(spanObj.spanText) &&
                                                                                            <span
                                                                                            style={spanObj.spanStyle}
                                                                                            >{spanObj.spanText}</span>
                                                                                        }
                                                                                        {
                                                                                            item.isRiskMasterEnabled === "N" &&
                                                                                            <span
                                                                                            style={{
                                                                                                background:"#ddd",
                                                                                                color:"#000"
                                                                                            }}
                                                                                            >Disabled</span>
                                                                                        }
                                                                                    </h6>
                                                                                    {
                                                                                        item.isNotReqSelected === "Y" &&
                                                                                        <FilterCheckBox themeSettings={themeSettings}
                                                                                            style={{marginLeft:0}}>
                                                                                            <input 
                                                                                                type="checkbox"
                                                                                                name="isNotReqSelected"
                                                                                                id="isNotReqSelected"
                                                                                                disabled={true}
                                                                                            />
                                                                                            <label for="isNotReqSelected">Not Required</label>
                                                                                        </FilterCheckBox>
                                                                                    }
                                                                                </div>
                                                                                {
                                                                                    item.fieldType === TemplateConstants.FIELD_TYPE_TEXT &&
                                                                                    <div class="row custom-row">
                                                                                        <div class="col-md-12 custom-col">
                                                                                            <div class="editor-layout template-fields-ph">
                                                                                                {/* <ReactQuill
                                                                                                    value={item.placeholderValue}
                                                                                                    // value={item.placeholderValue} 
                                                                                                    // onChange={this.handleChangeTask}
                                                                                                    className="three-lines-focus"
                                                                                                    modules={this.modules}
                                                                                                    // onFocus={this.onTaskFocus}
                                                                                                    // onBlur={this.onTaskBlur}
                                                                                                    readOnly={true}
                                                                                                /> */}
                                                                                                <textarea placeholder={item.placeholderValue} disabled="true"/>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    item.fieldType === TemplateConstants.FIELD_TYPE_DATE &&
                                                                                    <DatePicker
                                                                                        // onChange={this.onStartDateChange}
                                                                                        format={"DD/MM/YYYY"}
                                                                                        // disabled={true}
                                                                                        placeholder={item.placeholderValue}
                                                                                    />

                                                                                }
                                                                                {
                                                                                    item.fieldType === TemplateConstants.FIELD_TYPE_DROPDOWN &&
                                                                                    <RegularDropdown 
                                                                                        placeholder={item.placeholderValue}
                                                                                        dropdownArr={item.dropdownValues}
                                                                                        // labelParam="value"
                                                                                        labelParam={item.showDescription==="Y"?"valueNdesc":"value"}
                                                                                        onDropDownItemClick={()=>{}}
                                                                                        // isFocusRequired={true}
                                                                                        // defaultPlaceholderDropDown = {fieldTypePlaceholderText}
                                                                                    />

                                                                                }
                                                                                {
                                                                                    item.fieldType === TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI &&
                                                                                    item.isFromRiskMaster &&
                                                                                    <MultiSelectComponent 
                                                                                        id={"multi"+index}
                                                                                        dataSource={this.state.businessRisk}
                                                                                        fields={item.showDescription === "Y"?fieldsWithDesc:fields}
                                                                                        placeholder={item.placeholderValue} 
                                                                                        mode="CheckBox"
                                                                                        closePopupOnSelect={false}
                                                                                        allowFiltering={false}
                                                                                        readOnly={true}
                                                                                        cssClass="clientsChecklist dialogcl"
                                                                                        showDropDownIcon={true}
                                                                                        showClearButton={false}
                                                                                        // select={()=>{}}
                                                                                        // removed={()=>{}}
                                                                                        // value={this.state.selectedRiskIdArr}
                                                                                    >
                                                                                        <Inject services={[CheckBoxSelection]} />
                                                                                    </MultiSelectComponent>

                                                                                }
                                                                                {
                                                                                    item.fieldType === TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI &&
                                                                                    !item.isFromRiskMaster &&
                                                                                    <MultiSelectComponent 
                                                                                        id={"multi"+index}
                                                                                        dataSource={item.dropdownValues}
                                                                                        fields={item.showDescription === "Y"?fieldsWithDesc:fields}
                                                                                        placeholder={item.placeholderValue} 
                                                                                        mode="CheckBox"
                                                                                        closePopupOnSelect={false}
                                                                                        allowFiltering={false}
                                                                                        readOnly={true}
                                                                                        cssClass="clientsChecklist dialogcl"
                                                                                        showDropDownIcon={true}
                                                                                        showClearButton={false}
                                                                                        // select={()=>{}}
                                                                                        // removed={()=>{}}
                                                                                        // value={this.state.selectedRiskIdArr}
                                                                                    >
                                                                                        <Inject services={[CheckBoxSelection]} />
                                                                                    </MultiSelectComponent>
                                                                                }
                                                                                
                                                                                {
                                                                                    item.fieldType === TemplateConstants.FIELD_TYPE_TABLE &&
                                                                                    <div className="flex-center-layout" style={{justifyContent:"center"}}>
                                                                                        <AddNewButtonLayout themeSettings={themeSettings}>
                                                                                            <a href="javascript:void(0);">
                                                                                                <span className="material-icons">add_circle</span>
                                                                                                <p>Add Table</p>
                                                                                            </a>
                                                                                        </AddNewButtonLayout>
                                                                                    </div>
                                                                                }
                                                                                
                                                                            </div>
                                                                            {
                                                                                !isDetails &&
                                                                                <div>
                                                                                    <TableDropDown
                                                                                        actionArr={actionObj.actionArr}
                                                                                        onDropDownItemClick={this.onTableDropDownItemClick}
                                                                                        dropDownId={item}
                                                                                        dropdownWidth={actionObj.dropdownWidth}
                                                                                        themeSettings={themeSettings}
                                                                                        udf1 = {index}
                                                                                        udf2 = {"Right"}
                                                                                    />
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    )}
                                                                                    
                                                                </Draggable>
                                                            })
                                                        }
                                                        {provided.placeholder} 
                                                        </div>
                                                    )}
                                                </Droppable>
                                                }
                                                {
                                                    !isDetails &&
                                                    <div className="add-more-btn-layout" onClick={this.handleAddNewFieldClick}>
                                                        <span className="material-icons">add_circle</span>
                                                        <p>Add Field</p>
                                                    </div>
                                                }
                                            </div>
                                            </DragDropContext>
                                        </div>
                                        {/* <div className="row custom-row">
                                            <div className="col-md-3 custom-col">
                                                <div className="add-more-btn-layout" onClick={this.handleAddNewFieldClick}>
                                                    <span className="material-icons">add_circle</span>
                                                    <p>Add Field</p>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="row custom-row">
                                            <div className="col-md-12 custom-col">
                                                <div className="common-close-save m-b-20">
                                                    <div className="common-close-save-col">
                                                        <button onClick={this.onCancelClick} type="button" className="common-close">Cancel</button>
                                                        
                                                        {
                                                            !isDetails &&
                                                            <PageSaveButton 
                                                            themeSettings = {themeSettings}
                                                            onClick={this.handleSaveTemplateValidation} type="button">{this.state.showLoader ? <Spinner animation="border" variant="light" className='spinner-border-sm' /> : 'Save'}</PageSaveButton>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        
                        <AddFieldDialog
                            isEdit={this.state.isEditField}
                            showCreateDialog = {this.state.showCreateDialog}
                            handleCreateDialogClose = {this.handleCreateDialogClose}
                            themeSettings={themeSettings}
                            editTemplateItem={this.state.editTemplateItem}
                            isUsedInTasks={this.state.fieldUsedInTasks}
                            editTemplateItem_prev={this.state.editTemplateItem_prev}
                            addfieldTypePlaceholder={this.state.addfieldTypePlaceholder}
                            addToPosition={this.state.addToPosition}
                            fieldNameArray={this.state.fieldNameArray}
                            templateType = {TemplateConstants.TEMPLATE_TYPE_OBSERVATION}
                        />

                        <AddTemplateNamedialog
                            isEdit={this.state.isEditTemplateName}
                            showAddTemplateNameDialog={this.state.showAddTemplateNameDialog}
                            handleAddEditDialogClose={this.handleEditTemplateNameDialogClose}
                            themeSettings={themeSettings}
                            templateName={this.state.isEdit?this.state.templateName:""}
                        />

                        <AlertDialog 
                            showAlertDialog={this.state.showAlertDialog}
                            handleAlertDialogClose={this.handleAlertDialogClose}
                            type={
                                (this.state.operationType === "Delete")
                                ?
                                Constants.ALERT_TYPE_WARNING
                                :
                                Constants.ALERT_TYPE_INFO
                            }
                            alertDialogMessage={this.state.alertDialogMessage}
                            proceedBtnClick={this.handleRecordOperation}
                            proceedBtnLabel={this.state.proceedBtnLabel }
                            themeSettings={themeSettings}
                        />

                        <AlertDialog 
                            showAlertDialog={this.state.showSecondAlertDialog}
                            handleAlertDialogClose={this.handleSecondAlertDialogClose}
                            type={Constants.ALERT_TYPE_WARNING}
                            alertDialogMessage={this.state.secondAlertDialogMessage}
                            proceedBtnClick={this.handleDeleteFieldItem}
                            proceedBtnLabel={this.state.secondAlertProceedBtnLabel }
                            themeSettings={themeSettings}
                        />

                        <AlertDialog 
                            showAlertDialog={this.state.showAlertDialogInfo}
                            handleAlertDialogClose={this.handleAlertDialogCloseInfo}
                            type= {Constants.ALERT_TYPE_ALERT}
                            alertDialogMessage={this.state.alertDialogMessageInfo}
                            proceedBtnClick={this.handleAlertDialogCloseInfo}
                            proceedBtnLabel={ Constants.ALERT_TYPE_OKAY_LABEL }
                            themeSettings={themeSettings}
                        />

                        <AlertDialog 
                            showAlertDialog={this.state.isEdit && this.state.showTemplateAlertDialog}
                            handleAlertDialogClose={this.handleTemplateAlertDialogClose}
                            type= {Constants.ALERT_TYPE_ALERT}
                            alertDialogMessage={templateAlertDialogMessage}
                            proceedBtnClick={this.handleTemplateAlertDialogClose}
                            proceedBtnLabel={ Constants.ALERT_TYPE_OKAY_LABEL }
                            themeSettings={themeSettings}
                        />

                        <LeftSideSelectDialog
                            showSelectDropdownDialog = {this.state.showSelectDropdownDialog}
                            handleSelectDropdownDialogClose = {this.handleSelectDropdownDialogClose}
                            fieldObj = {this.state.editTemplateItem}
                            viewOnly = {"N"}
                            themeSettings={themeSettings}
                        />

                        <DifferenceDialog
                            showDifferenceDialog = {this.state.showDifferenceDialog}
                            handleDifferenceDialogClose = {this.handleDifferenceDialogClose}
                            themeSettings = {themeSettings}
                        />

                        <SelectAssignments
                            showSelectAssignmentDialog = {this.state.showSelectAssignmentDialog}
                            assignments = {this.state.assignments}
                            updateAssignmentIds = {this.state.updateAssignmentIds}
                            handleSelectAssignmentDialogClose = {this.handleSelectAssignmentDialogClose}
                            returnSelectedAssignmentIds = {this.returnSelectedAssignmentIds}
                            companyType = {this.state.companyDetials.companyType}
                            themeSettings = {themeSettings}

                        />
                        
                    </section>
                </main>
            </Router>
        )
    }
}

export default AddEditTaskTemplates;