import React, { Component } from "react";
import * as Constants from "../../common/Global/constants";
import Sidebar from "../../common/Sidebar";
import Topbar from "../../common/Topbar";
import { DialogSaveButton } from "../../common/Global/globalStyles.style";
import { BrowserRouter as Router } from "react-router-dom";
import {
    getLocalStorageVariables, isEmptyVariable, isEmptyArray,
    isNumber, getThemeSettingsVariables
} from "../../common/Global/commonFunctions";
import AlertDialog from "../../common/AlertDialog";
import RegularDropdown from '../../common/DropdownMenus/RegularDropdown';
import SettingTopMenu from "./topmenu"
import { FilterCheckBox } from "../../common/Global/globalStyles.style";
import AddAssignmentGroupValues from "./Common/addAssignmentGroupValues";

let DeptSettingsFields = {
    daysToDeleteAssignment:{
        settingColumn:"daysToDeleteAssignment",
        settingDisplayColumn:"Days to delete assignment",
        settingColumnEnabled:"Y",
        settingValue:"14"
    },
    groupSet1:{
        settingColumn:"groupSet1",
        settingDisplayColumn:"Group Set 1 Name",
        settingColumnEnabled:"N",
        settingValue:""
    },
    groupSet2:{
        settingColumn:"groupSet2",
        settingDisplayColumn:"Group Set 2 Name",
        settingColumnEnabled:"N",
        settingValue:""
    },
    groupSet3:{
        settingColumn:"groupSet3",
        settingDisplayColumn:"Group Set 3 Name",
        settingColumnEnabled:"N",
        settingValue:""
    },
}

const departmentDropdownDefaultValue = "Select Department";
let userDetails = getLocalStorageVariables();
let themeSettings = getThemeSettingsVariables();

class departmentSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            componentDidMountFlag: false,

            showLoader: false,
            showBtnLoader: false,
            totalCount: 0,
            formErrors: {},
            menus: "",
            showAlertDialogInfo: false,
            alertDialogMessageInfo: "",
            selectedSettingsDepartment:DeptSettingsFields
        };
    }

    componentDidMount() {
        this.getInitData();
    }

    handleCheckBoxClick = (e) => {
        let isChecked = e.target.checked;
        const { name } = e.target;

        let selectedSettingsDepartment = this.state.selectedSettingsDepartment;
        selectedSettingsDepartment[name]["settingColumnEnabled"] = isChecked?"Y":"N";

        this.setState({
            selectedSettingsDepartment: selectedSettingsDepartment,
        });
    }

    handleTaskFieldsChange = (e) => {
        let FormErrorsTemp = this.state.formErrors;
        const { name, value } = e.target;

        let selectedSettingsDepartment = this.state.selectedSettingsDepartment;
        selectedSettingsDepartment[name]["settingValue"] = value;

        if (this.state.submitClickedAtleastOnce) {
            FormErrorsTemp[name]["settingColumn"] = "";
        }

        this.setState({
            selectedSettingsDepartment: selectedSettingsDepartment,
            formErrors: FormErrorsTemp
        });
    };

    handleAlertDialogCloseInfo = () => {
        this.setState({
            showAlertDialogInfo: false,
            alertDialogMessageInfo: "",
        });
    };


    deptDropdownClick = (item) => {
        let FormErrorsTemp = this.state.formErrors;

        if (this.state.submitClickedAtleastOnce) {
            FormErrorsTemp['deparment'] = "";
        }

        let filteredSettings = this.state.settingAssignment[item.departmentId];
        let selectedSettingsDepartment = {};

        if (isEmptyArray(Object.keys(filteredSettings))) {
            selectedSettingsDepartment = DeptSettingsFields;
        } else {
            selectedSettingsDepartment = filteredSettings;
        }

        this.setState({
            selectedDepartmentName: item.name,
            selectedDepartmentId: item.departmentId,
            formErrors: FormErrorsTemp,
            selectedSettingsDepartment: selectedSettingsDepartment,
        })
    }

    showGroupSetDialog = (settingColumn) => {
        this.setState({
            showGroupSetDialogFlag:true,
            selectedSettingColumn:settingColumn
        })
    }

    hideGroupSetDialog = () => {
        this.setState({
            showGroupSetDialogFlag:false,
            selectedSettingColumn:""
        })
    }

    /*******************API CALLS*******************/
    getInitData = (reloadFlag) => {
        this.setState({
            showLoader: true,
        });

        Promise.all([
            fetch(Constants.GetDeptSettings, {
                method: "POST",
                mode: "cors",
                body: new URLSearchParams({
                    email: userDetails.email,
                    accessToken: userDetails.accessToken,
                }),
            }),
            fetch(Constants.GetUserMenu,
                {
                    method: "POST",
                    mode: 'cors',
                    body: new URLSearchParams({
                        email: userDetails.email,
                        accessToken: userDetails.accessToken,
                    })
                }),

        ])
            .then(([generalRes, menuRes]) => {
                return Promise.all([generalRes.json(), menuRes.json()]);
            })
            .then(([generalRes, menuRes]) => {
                let menus = [];
                let user = {};
                let departmentsArr = [];
                if (menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
                    localStorage.clear();
                    window.location = "/";
                } else if (menuRes.responseCode === Constants.CODE_SUCCESS) {
                    menus = menuRes.data.menus;
                    user = menuRes.data.user;
                    departmentsArr = menuRes.data.departments;
                } else {
                }

                let settingAssignment = {};
                if (generalRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    generalRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
                    localStorage.clear();
                    window.location = "/";
                } else if (generalRes.responseCode === Constants.CODE_SUCCESS) {
                    settingAssignment = generalRes.data.departmentSettings;
                    
                } else {
                }

                if(!reloadFlag){
                    let selectedSettingsDepartment = "";
                    let selectedDepartmentId = "";
                    let selectedDepartmentName = departmentDropdownDefaultValue;
    
                    if (!isEmptyArray(departmentsArr)) {
                        selectedDepartmentId = departmentsArr[0].departmentId;
                        selectedDepartmentName = departmentsArr[0].name;
                        if (isEmptyArray(Object.keys(settingAssignment[selectedDepartmentId]))) {
                            selectedSettingsDepartment = DeptSettingsFields;
                        } else {
                            selectedSettingsDepartment = settingAssignment[selectedDepartmentId];
                        }
                    }
                    this.setState({
                        selectedDepartmentId: selectedDepartmentId,
                        selectedDepartmentName: selectedDepartmentName,
                        selectedSettingsDepartment: selectedSettingsDepartment,
                    })
                }

                this.setState({
                    showLoader: false,
                    componentDidMountFlag: true,
                    menus: menus,
                    departmentsArr: departmentsArr,
                    settingAssignment: settingAssignment,
                })

            });
    };

    handleSaveFields = () => {
        let isError = false;
        let formErrTemp = {};

        Object.values(this.state.selectedSettingsDepartment).forEach((item) => {
            if(item.showTextbox === "Y" && isEmptyVariable(item.settingValue) &&
                item.settingColumnEnabled === "Y"){
                isError = true;
                formErrTemp[item["settingColumn"]] = "Please enter the value"
            }
        });

        // if (!isNumber(this.state.selectedSettingsDepartment.daysToDelete)) {
        //     isError = true;
        //     formErrTemp["daysToDelete"] = "Please enter numeric values only";
        // }

        // if(!isNumber(this.state.selectedSettingsDepartment.daysToCompleteTask)){
        //     isError = true;
        //     formErrTemp["daysToCompleteTask"] = "Please enter numeric values only";
        // }
        if (isError) {
            this.setState({
                formErrors: formErrTemp
            });
        } else {
            this.setState({
                showBtnLoader: true,
            });
            fetch(Constants.UpdateDepartmentSettings, {
                method: "POST",
                mode: "cors",
                body: new URLSearchParams({
                    email: userDetails.email,
                    accessToken: userDetails.accessToken,
                    departmentId: this.state.selectedDepartmentId,
                    updatedValues: JSON.stringify(this.state.selectedSettingsDepartment),
                }),
            })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
                    localStorage.clear();
                    window.location = "/";
                } else if (data.responseCode === Constants.CODE_SUCCESS) {
                    this.setState({
                        showBtnLoader: false,
                        showAlertDialogInfo: true,
                        alertDialogMessageInfo: "Settings updated successfully",
                        formErrors: {},
                    },()=>{
                        this.getInitData(true);
                    });
                } else {
                    this.setState({
                        showBtnLoader: false,
                        showAlertDialogInfo: true,
                        alertDialogMessageInfo:data.responseMessage
                    })
                }
            });
        }
    };
    /*******************API CALLS END HERE*******************/

    render() {
        //"directScheduled" sorted to the end of the array
        const sortedSettings = Object.values(this.state.selectedSettingsDepartment).sort((a, b) => {
            if (a.settingColumn === "directScheduled") return 1;
            if (b.settingColumn === "directScheduled") return -1;
            return 0;
        });
        return (
            <Router>
                <main className="main-dashboard">
                    <section className="dashboard-bg">
                        <Sidebar />

                        <div className="dashboard-right-bg position-relative">
                            <div className="dashboard-wrapper">
                                <Topbar />
                                {
                                    this.state.componentDidMountFlag && 
                                        <SettingTopMenu themeSettings={themeSettings} menus={this.state.menus} />
                                }								
                                {this.state.showLoader && (
                                    <div class="loader"></div>
                                )}
                                {
                                    // Need to check component did mount flag, other wise until api is called,
                                    // it shows no items found layout.
                                    this.state.componentDidMountFlag && (
                                        <div className="body-wrapper">

                                            <div className="row custom-row">
                                                <div className="col-md-12 custom-col">
                                                    <div className="card-col">


                                                        {
                                                            !isEmptyArray(this.state.departmentsArr) &&
                                                            this.state.departmentsArr.length > 1 &&
                                                            <div class="addnew-modal-form-group">
                                                                <p>Department*</p>
                                                                <RegularDropdown
                                                                    placeholder={this.state.selectedDepartmentName}
                                                                    dropdownArr={this.state.departmentsArr}
                                                                    labelParam="name"
                                                                    onDropDownItemClick={this.deptDropdownClick}
                                                                    defaultPlaceholderDropDown={departmentDropdownDefaultValue}
                                                                />
                                                                {
                                                                    !isEmptyVariable(this.state.formErrors['deparment']) &&
                                                                    <span class="cm-error">{this.state.formErrors['deparment']}</span>
                                                                }
                                                            </div>
                                                        }

                                                        {
                                                            !isEmptyVariable(this.state.selectedDepartmentId) &&
                                                            Object.values(sortedSettings).map((item, index, array) => {
                                                                return (
                                                                    <div>
                                                                    {index === 0 && <p style={{fontWeight:400}}>Choose the number of days within which an assignment will be hard deleted and data cannot be retrieved. After these days assignment can be deleted and data can also be retrieved.</p>}
                                                                    {index === 1 && <p style={{fontWeight:400, marginTop:15}}>Create the group set to group the assignment in a particular category. Upto 3 group set can be created and desired name can be given.</p>}
                                                                    {index === 4 && <p style={{fontWeight:400, marginTop:15, marginBottom:15}}>Planning is carried out in two step process. To make planning one step process and to do direct scheduling, check the box below.</p>}
                                                                    
                                                                    <div className="row custom-row"  style={{borderBottom: index == 0 ? '1px solid #ccc' : index == 3 ? '1px solid #ccc' : 0}}>
                                                                        <div className="col-md-3 custom-col flex-center-layout">
                                                                            <div className="add-new-form-group">
                                                                                <p>{item["settingDisplayColumn"]}</p>
                                                                            </div>
                                                                            {/* </div> */}
                                                                        </div>
                                                                        <div className="col-md-8 custom-col mb-3">
                                                                            <div className="flex-center-layout">
                                                                            {
                                                                                item.showCheckbox === "Y" &&
                                                                                <FilterCheckBox
                                                                                    themeSettings={themeSettings}
                                                                                    style={{ marginLeft: 0, marginRight:10}}
                                                                                >
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        name={item["settingColumn"]}
                                                                                        id={item["settingColumn"]}
                                                                                        onChange={this.handleCheckBoxClick}
                                                                                        checked={item.settingColumnEnabled === "Y"}
                                                                                    />

                                                                                    <label for={item["settingColumn"]}></label>

                                                                                </FilterCheckBox>
                                                                            }
                                                                            {
                                                                                item.showTextbox === "Y" &&
                                                                                <div>
                                                                                    <input name={item["settingColumn"]} type="text"
                                                                                        value={item["settingValue"]}
                                                                                        disabled = {item.settingColumnEnabled !== "Y"}
                                                                                        onChange={this.handleTaskFieldsChange} />
                                                                                </div>
                                                                            }
                                                                            {
                                                                                item.settingColumn.includes("groupSet") && item.settingColumnEnabled == "Y" &&
                                                                                <button className="setting-add d-flex" 
                                                                                    onClick = {this.showGroupSetDialog.bind(this,item.settingColumn)}>
                                                                                    <span className="material-icons">add_circle</span>
                                                                                </button>
                                                                            }
                                                                            {
                                                                                !isEmptyVariable(this.state.formErrors[item["settingColumn"]]) &&
                                                                                <span className="cm-error m-t-0 m-l-5">{this.state.formErrors[item["settingColumn"]]}</span>
                                                                            }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                        {/* {
                                                            !isEmptyVariable(this.state.formErrors['apiErr']) &&
                                                            <span className="cm-error">{this.state.formErrors['apiErr']}</span>
                                                        } */}
                                                        {
                                                            !isEmptyVariable(this.state.selectedDepartmentId) &&
                                                            <div className="modal-close-save m-t-15">
                                                                <div className="close-save-col">
                                                                    <DialogSaveButton
                                                                        themeSettings={themeSettings}
                                                                        onClick={this.handleSaveFields}
                                                                        type="button"
                                                                        className="modal-save m-b-0"
                                                                    >
                                                                        {this.state.showBtnLoader ? <div class="loader-btn mt-2"></div> : 'Save'}
                                                                    </DialogSaveButton>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <AlertDialog
                            showAlertDialog={this.state.showAlertDialogInfo}
                            handleAlertDialogClose={this.handleAlertDialogCloseInfo}
                            type={Constants.ALERT_TYPE_ALERT}
                            alertDialogMessage={this.state.alertDialogMessageInfo}
                            proceedBtnClick={this.handleAlertDialogCloseInfo}
                            proceedBtnLabel={Constants.ALERT_TYPE_OKAY_LABEL}
                            themeSettings={themeSettings}
                        />

                        <AddAssignmentGroupValues
                            showGroupSetDialogFlag = {this.state.showGroupSetDialogFlag}
                            hideGroupSetDialog = {this.hideGroupSetDialog}
                            themeSettings={themeSettings}
                            selectedDepartmentId={this.state.selectedDepartmentId}
                            selectedSettingColumn={this.state.selectedSettingColumn}
                        />
                    </section>
                </main>
            </Router>
        );
    }
}

export default departmentSettings;
