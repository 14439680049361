import React, { Component } from 'react';
import * as Constants from '../../../common/Global/constants';
import {TaskTagButtonsLayout,FilterCheckBox,AddNewButtonLayout} from '../../../common/Global/globalStyles.style';
import SelectedDocumentDialog from './selectedDocument';
import {getLocalStorageVariables,getThemeSettingsVariables,removeHtmlTags,ifEmptyReturn,getStringWithSpaceAfterComma,
    isEmptyVariable, isEmptyArray, isJsonString} from '../../../common/Global/commonFunctions';
import {
    BrowserRouter as Router,
} from 'react-router-dom';
import 'react-quill/dist/quill.snow.css';
import * as TemplateConstants from '../../../common/Global/templateConstants';
import moment from "moment";

const themeSettings  = getThemeSettingsVariables();
const userDetails  = getLocalStorageVariables();

//if the status is draft or created then there will be auto save other wise no...
const emptyString = "-";
const notRequiredString = "Not Required";

class TaskDetails extends Component{
    
    constructor(props) {
        super(props);

        this.state = {
            menus:{
                Tasks:"ALL",
                Checklist:"ALL"
            },

            departmentArr:[],
            deptDefaultRisk:{},
            businessRisk:[],
            componentDidMountFlag:false,
            datalist:[],
            datalistLeft:[],

            taskId:this.props.taskId,
            processId:"",
            
            templateFieldValuesMap:{},
            industrySelectAllExceptNames:[],
            // formErrors:{},
            formCustomRisk:"",
            //TAGS
            //Document
            showSelectDocumentDialog:false,
            selectedDocumentObjArr:[],
            document_NR:"",
            //Alert Info Dialog
            showAlertDialogInfo:false,
            alertDialogMessageInfo:"",
        }
    }

    componentDidMount(){
        this.initData();
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props)){
            this.setState({
                menus:{
                    Tasks:"ALL",
                    Checklist:"ALL"
                },
    
                departmentArr:[],
                deptDefaultRisk:{},
                businessRisk:[],
                componentDidMountFlag:false,
                datalist:[],
                datalistLeft:[],
                formCustomRisk : "",
    
                taskId:this.props.taskId,
                processId:"",
                templateFieldValuesMap:{},
                // formErrors:{},
                
                //Document
                showSelectDocumentDialog:false,
                selectedDocumentObjArr:[],
                document_NR:"",
                //Alert Info Dialog
                showAlertDialogInfo:false,
                alertDialogMessageInfo:"",
            },()=>{
                this.initData();
            });
        }
    }

    //Document
    handleSelectDocumentDialogClose = () => {
        this.setState({
            showSelectDocumentDialog:false,
        })
    }
    handleSelectDocumentDialogShow = () => {
        this.setState({
            showSelectDocumentDialog:true,
        })
    }
    /************************API CALLS****************************/

    initData = () =>{
        this.setState({
            showLoader:true,
        })

        Promise.all([
            fetch(Constants.GetDraftTaskCreateMasterData,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    taskId:this.state.taskId,
                    assignmentId:this.props.assignmentId
                })
            }),
            fetch(Constants.GetDraftTaskDetails,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    taskId:this.state.taskId,
                    assignmentId:this.props.assignmentId
                })
            }),
        ])
        .then(([masterRes, detailsRes])=>{
            return Promise.all([masterRes.json(),detailsRes.json()]) 
        })
        .then(([masterRes, detailsRes])=>{
            let businessRisks = [];
            let deptArrTemp = [];
            let selectedDeptId = "";
            let deptDefaultRisk = [];
            let businessRiskArr = [];
            let datalistTemp = [];
            let datalistLeftTemp = [];
            let datalist = [];
            let datalistLeft = [];
            let templateFieldValuesMap = {};
            let formCustomRisk = "";

            if(masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(masterRes.responseCode === Constants.CODE_SUCCESS){
                let businessRiskObj = {
                    businessRiskId:-1,
                    shortName:"",
                    name:"Other",
                    description:""
                }
                businessRisks = masterRes.data.businessRisk;
                businessRisks.push(businessRiskObj);
                
                //add property "displayname: name (description)" to businessRisk array
                businessRisks.forEach(item=>{
                    if(item.name !=="Other"){
                        item.displayName = item.name+" ("+item.description+")"
                        
                    }
                    else{
                        item.displayName = item.name
                    }
                    businessRiskArr.push(item);
                    
                });

                deptArrTemp = masterRes.data.departments;
                deptDefaultRisk = masterRes.data.deptDefaultRisk;
            }else{

            }

            let task = {};
            if(detailsRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                detailsRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(detailsRes.responseCode === Constants.CODE_SUCCESS){
                
                let taskObj = detailsRes.data.task;

                selectedDeptId = taskObj.departmentId;
                let processId = "";
                
                let tagList = taskObj.tagList;
                let selectedDocumentObjArr = [];
                let document_NR = "";
                let selectedFunctionObjArr = [];
                let selectedAssigmentTypeObjArr = [];
                let selectedIndustryObjArr = [];
                let selectAllExceptIds = "";
                let industrySelectAllExceptNames = [];
        

                taskObj.templateFieldValues.forEach(item=>{
                    templateFieldValuesMap[JSON.stringify(item.fieldId)] = item;
                });

                if(!isEmptyVariable(taskObj.industrySelectAllExcept) &&
                    taskObj.industrySelectAllExcept === "Y" &&
                    !isEmptyVariable(taskObj.rootIndustries)){

                    //now getting top level industries directly from server
                    industrySelectAllExceptNames = taskObj.rootIndustries;
                }

                //SAMPLE REPONSE
                //"tagList":"assertion:4:Completeness3:N,assertion:1:Completeness:N,assignmentType:4:IA Assignment from L3:N,
                // assignmentType:1:Sox Assignments From L3:N,document:55:Purchase New 6:N,document:51:Purchase 2:N,
                // function:30:Import Purchase:N,function:29:Local Purchase:N,industry:34:Goods 1.2.1:N,industry:30:Goods 1.2:N,
                // process:23:Purchase Order:N",
                if(!isEmptyVariable(tagList)){
                    let tagArr = tagList.split(",");

                    tagArr.map(item => {
                        let individualTagSplitArr = item.split(":");
                        
                        if(!isEmptyVariable(individualTagSplitArr[0])){
                            switch(individualTagSplitArr[0]){
                                case "document":
                                    if(individualTagSplitArr[3] === "Y"){
                                        document_NR = "Y";
                                    }else{
                                        let obj = {
                                            documentId:individualTagSplitArr[1],
                                            name:individualTagSplitArr[2],
                                        }
                                        selectedDocumentObjArr.push(obj);
                                    }
                                    break;
                                case "function":
                                    let functionObj = {
                                        functionId:individualTagSplitArr[1],
                                        functionName:individualTagSplitArr[2],
                                    }
                                    selectedFunctionObjArr.push(functionObj);
                                    
                                    break;
                                case "industry":
                                    let industryObj = {
                                        industryId:individualTagSplitArr[1],
                                        industryName:individualTagSplitArr[2],
                                    }
                                    selectedIndustryObjArr.push(industryObj);
                                    
                                    break;
                                case "assignmentType":
                                    let assignmentTypeObj = {
                                        id:individualTagSplitArr[1],
                                        name:individualTagSplitArr[2],
                                    }
                                    selectedAssigmentTypeObjArr.push(assignmentTypeObj);
                                    
                                    break;
                                case "process":
                                    processId = individualTagSplitArr[1];
                                    break;
                            }
                        }
                    })
                }

                //Now Risk
                let riskObj = {
                    riskLevelId:taskObj.riskLevelId,
                    riskLevel:taskObj.riskLevel
                }

                if(!isEmptyVariable(taskObj.templateFields) && isJsonString(taskObj.templateFields)){
                    datalistTemp = JSON.parse(taskObj.templateFields);
                }
                if(!isEmptyVariable(taskObj.templateFieldsLeft) && isJsonString(taskObj.templateFieldsLeft)){
                    datalistLeftTemp = JSON.parse(taskObj.templateFieldsLeft);
                }

                //Add fieldValue and isNRChecked from ValuesMap to datalist
                datalist = datalistTemp.map(item=>{
                    if(item.fieldType === TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI ||
                        item.fieldType === TemplateConstants.FIELD_TYPE_TABLE ){

                        if(!isEmptyVariable(templateFieldValuesMap[item.fieldId]?.value) &&
                            templateFieldValuesMap[item.fieldId]?.valueNR !== "Y" &&
                            isJsonString(templateFieldValuesMap[item.fieldId]?.value)
                        ){
                            item.fieldValue = JSON.parse(templateFieldValuesMap[item.fieldId]?.value);
                            if(item.fieldType === TemplateConstants.FIELD_TYPE_TABLE){
                                item.isTableAdded = true;
                            }
                        }else{
                            item.fieldValue = [];
                        }

                    }else{
                        if(templateFieldValuesMap[item.fieldId]?.valueNR !== "Y"){
                            item.fieldValue = templateFieldValuesMap[item.fieldId]?.value;
                        }
                    }

                    item.isNRChecked = templateFieldValuesMap[item.fieldId]?.valueNR;
                    return item;
                });

                datalistLeft = datalistLeftTemp.map(item=>{
                    if(item.fieldType === TemplateConstants.FIELD_TYPE_MULTI_SELECT){
                        if(!isEmptyVariable(templateFieldValuesMap[item.fieldId]?.value) &&
                            templateFieldValuesMap[item.fieldId]?.valueNR !== "Y" &&
                            isJsonString(templateFieldValuesMap[item.fieldId]?.value)
                        ){

                            item.fieldValue = JSON.parse(templateFieldValuesMap[item.fieldId]?.value);
                        }else{
                            item.fieldValue = [];
                        }
                    }else{
                        if(templateFieldValuesMap[item.fieldId]?.valueNR !== "Y"){
                            item.fieldValue = templateFieldValuesMap[item.fieldId]?.value;
                        }
                    }
                    item.isNRChecked = templateFieldValuesMap[item.fieldId]?.valueNR;
                    return item;
                });

                task = {

                    processId:processId,
                    
                    document_NR:document_NR,
                    selectedDocumentObjArr:selectedDocumentObjArr,
                    selectedIndustryObjArr:selectedIndustryObjArr,
                    selectedFunctionObjArr:selectedFunctionObjArr,
                    selectedAssigmentTypeObjArr:selectedAssigmentTypeObjArr,
                    
                    industrySelectAll:taskObj.industrySelectAll,
                    industrySelectAllExcept:taskObj.industrySelectAllExcept,
                    industrySelectAllExceptIds:selectAllExceptIds,
                    industrySelectAllExceptNames:industrySelectAllExceptNames,
                    
                    selectedRiskObj:riskObj,
                    status:taskObj.status,
                }
                //Get Other Risk/Impact Value
                if(!isEmptyVariable(taskObj.customBusinessRisk)){
                    formCustomRisk = taskObj.customBusinessRisk;
                }
            }
            
            this.setState({
                showLoader:false,
                componentDidMountFlag:true,
                departmentArr:deptArrTemp,
                selectedDepartmentId:selectedDeptId,
                deptDefaultRisk:deptDefaultRisk,
                businessRisk:businessRiskArr,
                
                ...task,
                datalist: datalist,
                datalistLeft: datalistLeft,
                templateFieldValuesMap:templateFieldValuesMap,
                formCustomRisk:formCustomRisk
            });
        })
    }

    getTaskTagCSV = (arr,name) => {
        let csvStr = "";
        arr.map((item,idx) =>{
            if(idx === 0){
                csvStr = item[name];
            }else{
                csvStr = csvStr+", "+item[name];
            }
        })
        return csvStr;
    }

    nextTask = () => {
        this.props.handleShowTaskDetailsLayout(this.props.nextTaskId)
    }

    prevTask = () => {
        this.props.handleShowTaskDetailsLayout(this.props.prevTaskId)
    }

    render(){
        
        return(
            <Router>
            {
                // Need to check component did mount flag, other wise until api is called,
                // it shows no items found layout.
                this.state.componentDidMountFlag &&
                <div className="m-t-10">
                    {
                        this.state.showLoader &&
                        <div class="loader"></div>
                    }
                    <div className="prev-next-layout">
                        <div className="flex-center-layout secondary-top-bar">
                            <div className="back-btn-layout" onClick={this.props.handleHideTaskDetailsLayout}>
                            <span class="material-icons-outlined">arrow_back_ios</span>
                            </div>
                            <h6>{"Task Details"}</h6>
                        </div>
                        <div className="flex-center-layout">
                            {
                                !isEmptyVariable(this.props.prevTaskId) &&
                                <AddNewButtonLayout themeSettings={themeSettings}>
                                    <a onClick={this.prevTask} href="javascript:void(0);">
                                        <span className="material-icons">arrow_back_ios</span>
                                        <p>Prev</p>
                                    </a>
                                </AddNewButtonLayout>
                            }
                            {
                                !isEmptyVariable(this.props.nextTaskId) &&
                                <AddNewButtonLayout themeSettings={themeSettings} style={{marginLeft:10}}>
                                    <a onClick={this.nextTask} href="javascript:void(0);">
                                        <p style={{marginRight:6,marginLeft:0}}>Next</p>
                                        <span className="material-icons">arrow_forward_ios</span>
                                    </a>
                                </AddNewButtonLayout>
                            }
                        </div>
                    </div>
                    <div className="row custom-row m-t-10">
                        <div className="col-md-3 custom-col">
                            <TaskTagButtonsLayout
                            themeSettings={themeSettings}
                            >
                                <ul>
                                    <li>
                                        <div className="tagLayout">
                                            <div className="tagHeadingLayout">
                                                <p>Industry</p>
                                                {
                                                    !isEmptyArray(this.state.selectedIndustryObjArr) &&
                                                    <span class="material-icons">check_circle</span>
                                                }
                                            </div>
                                            {
                                                isEmptyArray(this.state.selectedIndustryObjArr)
                                                ?
                                                this.state.industrySelectAll === "Y"
                                                ?
                                                <p>All Industries</p>
                                                :
                                                this.state.industrySelectAllExcept === "Y"
                                                ?
                                                <p>All Industries except {this.state.industrySelectAllExceptNames}</p>
                                                :
                                                <p>{emptyString}</p>
                                                :
                                                <p>{this.getTaskTagCSV(this.state.selectedIndustryObjArr,"industryName")}</p>
                                            }
                                            
                                        </div>
                                    </li>
                                    <li>
                                        <div className="tagLayout">
                                            <div className="tagHeadingLayout">
                                                <p>Documents</p>
                                                {
                                                    (!isEmptyArray(this.state.selectedDocumentObjArr) ||
                                                    this.state.document_NR ==="Y") &&
                                                    <span class="material-icons">check_circle</span>
                                                }
                                            </div>
                                            {
                                                this.state.document_NR ==="Y"
                                                ?
                                                <p>{notRequiredString}</p>
                                                :
                                                    isEmptyArray(this.state.selectedDocumentObjArr)
                                                    ?
                                                    <p>{emptyString}</p>
                                                    :
                                                    <div>
                                                        <p>{this.getTaskTagCSV(this.state.selectedDocumentObjArr,"name")}</p>
                                                        <button onClick={this.handleSelectDocumentDialogShow}>View Details</button>
                                                    </div>
                                            }
                                            
                                        </div>
                                    </li>
                                    <li>
                                        <div className="tagLayout">
                                            <div className="tagHeadingLayout">
                                                <p>Function</p>
                                                {
                                                    !isEmptyArray(this.state.selectedFunctionObjArr) &&
                                                    <span class="material-icons">check_circle</span>
                                                }
                                            </div>
                                            {
                                                isEmptyArray(this.state.selectedFunctionObjArr)
                                                ?
                                                <p>{emptyString}</p>
                                                :
                                                <p>{this.getTaskTagCSV(this.state.selectedFunctionObjArr,"functionName")}</p>
                                            }
                                        </div>
                                    </li>
                                    <li>
                                        <div className="tagLayout">
                                            <div className="tagHeadingLayout">
                                                <p>Risk</p>
                                                {
                                                    !isEmptyVariable(this.state.selectedRiskObj.riskLevelId) &&
                                                    <span class="material-icons">check_circle</span>
                                                }
                                            </div>
                                            {
                                                isEmptyVariable(this.state.selectedRiskObj.riskLevelId)
                                                ?
                                                <p>{emptyString}</p>
                                                :
                                                <p>{this.state.selectedRiskObj.riskLevel}</p>
                                            }
                                        </div>
                                    </li>
                                    {
                                        this.state.datalistLeft.map((item,index)=>{
                                            if(item.fieldType === TemplateConstants.FIELD_TYPE_SINGLE_SELECT){
                                                return  <li>
                                                    <div className="tagLayout">
                                                        <div className="tagHeadingLayout">
                                                            <p>{item.fieldName}</p>
                                                            {
                                                                !isEmptyVariable(item.fieldValue) &&
                                                                <span class="material-icons">check_circle</span>
                                                            }
                                                        </div>
                                                        {
                                                            isEmptyVariable(item.fieldValue)
                                                            ?
                                                            <p>{emptyString}</p>
                                                            :
                                                            <p>{item.fieldValue}</p>
                                                        }
                                                    </div>
                                                </li>

                                            }else if(item.fieldType === TemplateConstants.FIELD_TYPE_MULTI_SELECT){
                                                return  <li>
                                                    <div className="tagLayout">
                                                        <div className="tagHeadingLayout">
                                                            <p>{item.fieldName}</p>
                                                            {
                                                                !isEmptyArray(item.fieldValue) &&
                                                                <span class="material-icons">check_circle</span>
                                                            }
                                                            
                                                        </div>
                                                        {
                                                            isEmptyArray(item.fieldValue)
                                                            ?
                                                            <p>{emptyString}</p>
                                                            :
                                                            <p>{item.fieldValue.join(", ")}</p>
                                                        }
                                                    </div>
                                                </li>

                                            }
                                        })
                                    }

                                    {/* {
                                        this.state.datalistLeft.map((item,index)=>{
                                            return (
                                                <li>
                                                    {
                                                        item.fieldType === TemplateConstants.FIELD_TYPE_DROPDOWN &&
                                                        <div className="tagLayout">
                                                            <div className="tagHeadingLayout">
                                                                <p>{item.fieldName}{item.isMandatorySelected === "Y"?"*":""}</p>
                                                                {
                                                                    !isEmptyVariable(item.fieldValue) &&
                                                                    <span class="material-icons">check_circle</span>
                                                                }
                                                            </div>
                                                            {
                                                                isEmptyVariable(item.fieldValue)
                                                                ?
                                                                <p>{emptyString}</p>
                                                                :
                                                                <p>{item.fieldValue}</p>
                                                            }
                                                        </div>
                                                    }
                                                    {
                                                        item.fieldType === TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI &&
                                                        <div className="tagLayout">
                                                            <div className="tagHeadingLayout">
                                                                <p>{item.fieldName}{item.isMandatorySelected === "Y"?"*":""}</p>
                                                                {
                                                                    !isEmptyArray(item.fieldValue) &&
                                                                    <span class="material-icons">check_circle</span>
                                                                }
                                                            </div>
                                                            {
                                                                isEmptyArray(item.fieldValue)
                                                                ?
                                                                <p>{emptyString}</p>
                                                                :
                                                                <p>{item.fieldValue.join(", ")}</p>
                                                            }
                                                        </div>
                                                    }
                                                </li>
                                            )
                                        })
                                    } */}
                                </ul>
                            </TaskTagButtonsLayout>
                        </div>
                        <div className="col-md-9 custom-col">
                            {
                                this.state.datalist.map((item,index)=>{
                                    return (

                                    <div class="editor-card">
                                        <div className={item.isNotReqSelected === "Y"?"task-header-layout editor-border-bottom m-b-0":"task-header-layout editor-border-bottom"}>
                                            <h6 className="editor-heading">
                                            {item.fieldName}{item.isMandatorySelected === "Y"?"*":""}
                                            </h6>
                                            {
                                                item.isNotReqSelected === "Y"
                                                ?
                                                    <FilterCheckBox themeSettings={themeSettings}
                                                    style={{marginLeft:0}}>
                                                        <input 
                                                            type="checkbox"
                                                            name={item.fieldName+":-:"+index}
                                                            id={item.fieldName+":-:"+index}
                                                            onChange={this.handleCheck}
                                                            disabled={true}
                                                            checked={item.isNRChecked === "Y"?true:false}
                                                        />
                                                        <label for={item.fieldName+":-:"+index}>Not Required</label>
                                                    </FilterCheckBox>
                                                :
                                                    <div />
                                            }
                                        </div>

                                        {
                                            item.fieldType === TemplateConstants.FIELD_TYPE_TEXT &&
                                            <div class="row custom-row">
                                                <div class="col-md-12 custom-col">
                                                    {
                                                        item.isNRChecked !== "Y" &&
                                                        <div class="editor-text-layout">
                                                            {
                                                                isEmptyVariable((item.fieldValue))
                                                                ?
                                                                <p>{emptyString}</p>
                                                                :
                                                                    (isEmptyVariable(removeHtmlTags(item.fieldValue))
                                                                    ?<p>{emptyString}</p>
                                                                    :
                                                                    <div dangerouslySetInnerHTML={{__html: item.fieldValue}} />)
                                                            }
                                                            
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        }
                                        {
                                            item.fieldType === TemplateConstants.FIELD_TYPE_DATE &&
                                            <div class="row custom-row">
                                                <div class="col-md-12 custom-col">
                                                    {
                                                        item.isNRChecked !== "Y" &&
                                                        <div class="editor-text-layout">
                                                            {
                                                                isEmptyVariable((item.fieldValue))
                                                                ?
                                                                <p>{emptyString}</p>
                                                                :
                                                                <p>{moment(item.fieldValue).format("DD/MM/YYYY")}</p>
                                                            }
                                                            
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        }
                                        {
                                            item.fieldType === TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI &&
                                            item.isFromRiskMaster &&
                                            <div class="row custom-row">
                                                <div class="col-md-12 custom-col">
                                                    <div class={item.isNRChecked === "Y"?"editor-disabled":""}>
                                                    {
                                                        isEmptyArray(item.fieldValue)?
                                                        <p className="m-b-0">-</p>
                                                        :
                                                        (
                                                        <ul style={{marginBottom:5,paddingLeft:25}}>
                                                            {
                                                                this.state.businessRisk.map(riskItem=>{
                                                                    if(item.fieldValue.includes(riskItem.businessRiskId)){

                                                                        return(
                                                                            <li className="m-b-0 font-size-p">{riskItem.displayName}</li>
                                                                        )
                                                                    }
                                                                })  
                                                            }
                                                        </ul>)
                                                    }
                                                    {
                                                        item.fieldValue.some(fieldItem => fieldItem === -1)  &&
                                                        <div class="editor-text-layout">
                                                            <div style={{marginLeft:25}}
                                                            dangerouslySetInnerHTML={{__html: this.state.formCustomRisk}} />
                                                        </div>
                                                    }
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            item.fieldType === TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI &&
                                            !item.isFromRiskMaster &&
                                            <div class="row custom-row">
                                                <div class="col-md-12 custom-col">
                                                    <div class={item.isNRChecked === "Y"?"editor-disabled":""}>
                                                    {
                                                        isEmptyArray(item.fieldValue)?
                                                        <p className="m-b-0">-</p>
                                                        :
                                                        (
                                                        <ul style={{marginBottom:5,paddingLeft:25}}>
                                                            {
                                                                item.fieldValue.map(valueItem=>{

                                                                    return(
                                                                        <li className="m-b-0 font-size-p">{valueItem}</li>
                                                                    )
                                                                })  
                                                            }
                                                        </ul>)
                                                    }
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        
                                        {
                                            item.fieldType === TemplateConstants.FIELD_TYPE_DROPDOWN &&
                                            <div class="row custom-row">
                                                <div class="col-md-12 custom-col">
                                                    <div class={item.isNRChecked === "Y"?"editor-disabled":""}>
                                                    {
                                                        isEmptyVariable(item.fieldValue)?
                                                        <p className="m-b-0">-</p>
                                                        :
                                                        <p>{item.fieldValue}</p>
                                                    }
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        {
                                            item.fieldType === TemplateConstants.FIELD_TYPE_TABLE &&
                                            <div className="flex-center-layout" style={{justifyContent:"center"}}>
                                                {
                                                    !item.isTableAdded &&
                                                    <p>{emptyString}</p> 
                                                }
                                                {
                                                    item.isTableAdded &&
                                                    <div
                                                        style={{ width: "100%", marginTop: 10, boxShadow: "none" }}
                                                        class={
                                                            item.isNRChecked === "Y"
                                                                ? "editor-layout editor-disabled common-table"
                                                                : "common-table editor-layout"
                                                        }
                                                    >
                                                
                                                        <table class="table instances-table">
                                                            
                                                            {
                                                                !isEmptyArray(item.fieldValue) && item.fieldValue.map((rowItem, rowIndex) =>  {
                                                                    if(rowIndex === 0){
                                                                        return <thead className="instances-thead">
                                                                            <tr>
                                                                            {
                                                                                rowItem.map((colItem, colIndex) => {
                                                                                    if(colIndex !== rowItem.length-1){

                                                                                        return <th className="text-center">
                                                                                            <div className="instances-table-header">
                                                                                                <p>
                                                                                                    {colItem}
                                                                                                </p>
                                                                                            </div>
                                                                                        </th>
                                                                                    }
                                                                                })
                                                                            }
                                                                            </tr>
                                                                            </thead>
                                                                            
                                                                    }else{
                                                                        return <tbody className="instances-thead">
                                                                            <tr>
                                                                            {
                                                                                rowItem.map((colItem, colIndex) => {
                                                                                    if(colIndex !== rowItem.length-1){

                                                                                        return <td className="text-center">
                                                                                            {colItem}
                                                                                        </td>
                                                                                    }
                                                                                })
                                                                            }
                                                                            </tr>
                                                                        </tbody>

                                                                    }

                                                                })
                                                            }
                                                        </table>
                                                    </div>
                                                }
                                            </div>
                                        }
                                        
                                    </div>
                                    )
                                })
                            }
                            
                        </div>
                    
                    </div>
                    <SelectedDocumentDialog
                        showDocumentDialog={this.state.showSelectDocumentDialog}
                        handleSelectDocDialogClose={this.handleSelectDocumentDialogClose}
                        taskId={this.state.taskId}
                        subscriptionType={this.props.subscriptionType}
                        themeSettings = {themeSettings}
                    />
                </div>
            }
            </Router>
        )
    }
}

export default TaskDetails;